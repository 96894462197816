body {
    font-size: 14px!important;
    background: #f9f9f9!important;
}

#container {
    padding: 0!important;
    margin: 0!important;
}

@media only screen and (max-width: 767px) {
    .graf-cover {
        max-height: 250px;
    }
}